/*富文本编辑图片上传配置*/

const uploadConfig = {
	action: 'https://file.qidian.shop/api/image/upload?PlatForm=melyshop&Path=image&Token=c1dd023d-1053-47c2-ac62-f9d31ee9c3c3', // 必填参数 图片上传地址
	actionVideo: 'https://file.qidian.shop/api/image/upload?PlatForm=melyshop&Path=video&Token=c1dd023d-1053-47c2-ac62-f9d31ee9c3c3', // 必填参数 视频上传地址
	methods: 'POST', // 必填参数 图片上传方式
	token: 'c1dd023d-1053-47c2-ac62-f9d31ee9c3c3', // 可选参数 如果需要token验证，假设你的token有存放在sessionStorage
	name: 'img', // 必填参数 文件的参数名
	// nameVideo: 'video',
	size: 500, // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
	accept: 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon,image/gif,image/tif', // 可选 可上传的图片格式
	acceptVideo: 'AVI,mov,rmvb,rm,FLV,mp4,3GP,flv,video/*' //可选 可上传的视频格式
	
};

// toolbar工具栏的工具选项（默认展示全部）
const toolOptions = [
	['bold', 'italic', 'underline', 'strike'],
	['blockquote', 'code-block'],
	[{
		'header': 1
	}, {
		'header': 2
	}],
	// 有序列表和无序列表
  // [{
  //  'list': 'ordered'
  // }, {
  //  'list': 'bullet'
  // }],
	[{
		'script': 'sub'
	}, {
		'script': 'super'
	}],
	[{
		'indent': '-1'
	}, {
		'indent': '+1'
	}],
	[{
		'direction': 'rtl'
	}],
	[{
		'size': ['small', false, 'large', 'huge']
	}],
	[{
		'header': [1, 2, 3, 4, 5, 6, false]
	}],
	[{
		'color': []
	}, {
		'background': []
	}],
	[{
		'font': []
	}],
	[{
		'align': []
	}],
	['clean'],
	['link', 'image']
	 // , 'video'

];

var imgList = [];
var canUpload = true

function uploadImage(index, fileInput, callback) {
	if(!canUpload) return 
	if (!imgList[index]) {
		fileInput.value = '';
		return;
	}

	var that = this;

	var formData = new FormData();
	formData.append(uploadConfig.name, imgList[index]);
	formData.append('object', 'product');

	// 如果需要token且存在token
	if (uploadConfig.token) {
		formData.append('token', uploadConfig.token)
	}
	// 图片上传
	var xhr = new XMLHttpRequest();

	xhr.open(uploadConfig.methods, uploadConfig.action, true);
	// 上传数据成功，会触发

	xhr.onload = function(e) {
		if (xhr.status === 200) {
			// console.log(xhr.responseText,'上传之后')
			var res = JSON.parse(xhr.responseText);
			callback(res)
		}
		//fileInput.value = '';

		uploadImage(++index, fileInput, callback);
	};
	// 开始上传数据
	xhr.upload.onloadstart = function(e) {
		//fileInput.value = ''
	};
	// 当发生网络异常的时候会触发，如果上传数据的过程还未结束
	xhr.upload.onerror = function(e) {
		canUpload = false
		console.log(e)
	};
	// 上传数据完成（成功或者失败）时会触发
	xhr.upload.onloadend = function(e) {
		// console.log('上传结束')
	};
	xhr.send(formData)
}


const handlers = {
	image: function image() {
		var self = this;

		var fileInput = this.container.querySelector('input.ql-image[type=file]');
		if (fileInput === null) {
			fileInput = document.createElement('input');
			fileInput.setAttribute('type', 'file');

			//1
			fileInput.setAttribute('multiple', 'multiple');

			// 设置图片参数名
			if (uploadConfig.name) {
				fileInput.setAttribute('name', uploadConfig.name);
			}
			// 可设置上传图片的格式
			fileInput.setAttribute('accept', uploadConfig.accept);
			fileInput.classList.add('ql-image');

			// 监听选择文件
			fileInput.addEventListener('change', function(e) {
				// 创建formData      
				// console.log(fileInput.files,666)
				var arr = e.target.files;
				
				// var arr = Object.values(fileInput.files);
				
				// var arr=[]
				// arr.push(fileInput.files[0])
				
				
				//                 console.log(arr[0].size,"arrImg")
				// 
				//                 const isLt10M = arr[0].size / 1024 / 1024  < 10;
				//                 if (!isLt10M) {
				//                     alert('上传图片大小不能超过10MB哦!');
				//                     return false;
				//                 }
				imgList = arr;
				uploadImage(0, fileInput, function(res) {
					let length = self.quill.getSelection(true).index;
					
					//这里很重要，你图片上传成功后，img的src需要在这里添加，res.path就是你服务器返回的图片链接。            
					self.quill.insertEmbed(length, 'image', 'https://cdn.dkycn.cn/melyshop' + res[0] + '@!cut640');
					self.quill.setSelection(length + 1)
					self.quill.insertEmbed(length, '\n')

				});
				// arr.map(item=>{

				// })
			});

			this.container.appendChild(fileInput);
		}
		fileInput.click();
	},

	// uploadImage: function(index) {},

	video: function video() {
		var self = this;

		var videoInput = this.container.querySelector('input.inputName[type=file]');
		if (videoInput === null) {
			videoInput = document.createElement('input');
			videoInput.setAttribute('type', 'file');
			videoInput.setAttribute('multiple', 'multiple');
			videoInput.setAttribute('style', 'visibility:hidden');
			// 设置图片参数名
			if (uploadConfig.name) {
				videoInput.setAttribute('name', uploadConfig.nameVideo);
			}
			// 可设置上传图片的格式
			videoInput.setAttribute('accept', uploadConfig.acceptVideo);
			videoInput.classList.add('inputName');
			// 监听选择文件
			videoInput.addEventListener('change', function(e) {
				// 创建formData
				
				var arr = e.target.files;
				// var arr = Object.values(videoInput.files);
				
				// var arr=[]
				// arr.push(videoInput.files[0])
				
				
				//                 console.log(arr[0].size,"arrVideo")
				//                 const isLt50M = arr[0].size / 1024 / 1024  < 50;
				// if (isLt50M) {
				imgList = arr;
				uploadImage(0, videoInput, function(res) {
					let length = self.quill.getSelection(true).index;

					//这里很重要，你图片上传成功后，img的src需要在这里添加，res.path就是你服务器返回的图片链接。            
					self.quill.insertEmbed(length, 'video', 'https://cdn.dkycn.cn/melyshop' + res[0]);
					self.quill.setSelection(length + 2)
					// self.quill.insertEmbed(length,'\n')

				});

				//                 }else{                    
				//                     alert('上传视频大小不能超过50MB哦!');
				//                 }


				// arr.map(item=>{

				// })


			});

			this.container.appendChild(videoInput);
		}
		videoInput.click();
	},

};

export default {
	placeholder: '',
	theme: 'snow', // 主题
	modules: {
		toolbar: {
			container: toolOptions, // 工具栏选项
			handlers: handlers // 事件重写
		}
	}
};
